.App {
  text-align: center;
  background: rgb(38,77,89);
  background: linear-gradient(135deg, rgba(38,77,89,1) 60%, rgba(40,111,102,1) 94%);
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

